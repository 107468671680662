<template>
  <v-container fluid>
    <v-card elevation="0" class="pa-3">
      <v-card-text class="d-flex justify-space-between">
        <v-row>
          <v-col cols="12" md="6">
            <v-card-title class="pa-0 ma-0">
              {{
                itemData
                  ? $t("erp.lang_posItemName") + ": " + itemData.name
                  : null
              }}
            </v-card-title>

            <v-autocomplete
              v-model="selctedGarnish"
              :items="delegations"
              item-text="1"
              item-value="0"
              :label="$t('erp.lang_extra')"
              clearable
              dense
              outlined
              hide-details
              class="my-5"
            ></v-autocomplete>

            <v-card-actions class="pa-0 ma-0">
              <v-btn
                :disabled="this.loading"
                :loading="this.loading"
                @click="goBacktoItem"
                color="error"
                text
              >
                {{ $t("generic.lang_prev") }}
              </v-btn>

              <v-btn
                :disabled="this.loading || !this.selctedGarnish"
                :loading="this.loading"
                @click="saveData()"
                color="success"
              >
                {{ $t("generic.lang_save") }}
              </v-btn>
            </v-card-actions>
          </v-col>

          <v-col cols="12" md="6">
            <v-data-table
              :headers="filteredDatatableHeaders"
              :items="filteredDatatableEntries"
              :loading="datatableLoading"
              hide-default-header
              class="elevation-1"
            >
              <template v-slot:header="{ props: { headers } }">
                <thead>
                  <tr>
                    <th>
                      {{ headers[1].text }}
                    </th>
                    <th>
                      {{ headers[0].text }}
                    </th>
                  </tr>
                </thead>
              </template>

              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>
                      <v-simple-checkbox
                        v-model="item.assigned"
                        @click="editItem(item)"
                        color="primary"
                      >
                      </v-simple-checkbox>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        v-if="touchKeyboard.visible"
        id="onScreenKeyboard"
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidth"
      />
    </div>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import mixin from "@/mixins/KeyboardMixIns";
import { Events } from "@/plugins/events";
import Datatable from "../../datatable/Datatable";

export default {
  name: "EditMenuAssignmentComponent",
  components: {
    Datatable,
  },
  mixins: [mixin],

  data() {
    return {
      ENDPOINTS,
      loading: false,
      datatableLoading: false,
      itemData: [],
      menus: [],
      delegations: [],
      selctedGarnish: "",

      // id / assigned checkbox (true or false),EAN, name,, Itemgroup
      headers: [
        {
          text: "ID",
          value: "id",
          hide: true,
        },
        {
          text: this.$t("generic.lang_assigned"),
          value: "assigned",
        },
        {
          text: this.$t("erp.lang_ware_create_id"),
          value: "ean",
          hide: true,
        },
        {
          text: this.$t("generic.lang_name"),
          value: "name",
        },
        {
          text: "",
          value: "",
          hide: true,
        },
        {
          text: this.$t("generic.lang_waregroup"),
          value: "itemGroup",
          hide: true,
        },
      ],
    };
  },
  computed: {
    filteredDatatableHeaders: function () {
      let headers = [];

      this.headers.forEach((header) => {
        if (header.hasOwnProperty("hide")) {
          if (header.hide) {
            return;
          }
        }

        headers.push(header);
      });

      return headers;
    },
    filteredDatatableEntries: function () {
      let dataToRender = [];

      this.menus.forEach((data) => {
        let row = {};

        this.headers.forEach((header, index) => {
          row[header.value] = data[index];
        });

        dataToRender.push(row);
      });

      return dataToRender;
    },
    itemId() {
      return Number(this.$route.params.id);
    },
  },
  watch: {
    itemId: {
      handler(value) {
        if (value > 0) this.getItemByIdFromIndexedDB();
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.getMenus();
    this.getDelegations();
    this.getGarnishID();
  },
  methods: {
    async getItemByIdFromIndexedDB() {
      this.itemData = await this.$store.dispatch(
        "items/getItemByID",
        this.itemId
      );
    },
    goBacktoItem: function () {
      this.$router.push("/kiosk/menu-assignment");
    },
    getMenus() {
      this.datatableLoading = true;

      let params = {
        itemId: this.$route.params.id,
        is_menu: true,
      };

      this.axios
        .get(ENDPOINTS.DATATABLES.KIOSK.SEARCHMENUS, {
          params: params,
        })
        .then((res) => {
          this.menus = [];
          this.menus = res.data.aaData;
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });

          this.menus = [];
        })
        .finally(() => (this.datatableLoading = false));
    },
    editItem(item) {
      this.datatableLoading = true;

      let params = {
        itemId: this.$route.params.id,
        menuId: item.id,
        state: item.assigned,
      };

      this.axios
        .post(ENDPOINTS.KIOSK.MENU_ASSIGNEMENT.ITEM_DELEGATE_MENU, params)
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_success"),
              color: "success",
            });

            this.getMenus();
          } else {
            Events.$emit("showSnackbar", {
              color: "error",
              message: this.$t("generic.lang_errorOccurred"),
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
        })
        .finally(() => (this.datatableLoading = false));
    },

    getDelegations() {
      this.loading = true;

      let params = {
        itemID: this.$route.params.id,
      };

      this.axios
        .get(ENDPOINTS.DATATABLES.ERP.DELEGATEGARNISH, {
          params: params,
        })
        .then((res) => {
          this.delegations = res.data.aaData.filter((delegation) => {
            return !delegation[0].includes("-");
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getGarnishID() {
      this.loading = true;

      let params = {
        itemId: this.$route.params.id,
      };

      this.axios
        .get(ENDPOINTS.KIOSK.MENU_ASSIGNEMENT.MENU_GARNISH.GET, {
          params: params,
        })
        .then((res) => {
          this.selctedGarnish =
            typeof res.data.data.garnishId === "number" ||
            typeof res.data.data.garnishId === "string"
              ? res.data.data.garnishId.toString()
              : res.data.data.garnishId;
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
        })
        .finally(() => (this.loading = false));
    },
    saveData() {
      this.loading = true;

      let params = {
        itemId: this.$route.params.id,
        garnishId: this.selctedGarnish,
      };

      this.axios
        .post(ENDPOINTS.KIOSK.MENU_ASSIGNEMENT.MENU_GARNISH.UPDATE, params)
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_success"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              color: "error",
              message: this.$t("generic.lang_errorOccurred"),
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
